(function() {
  'use strict';

  angular
    .module('myPizza')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('myPizza', {
        url: '/my-pizza',
        views: {
          'mainView': {
            templateUrl: 'my-pizza/my-pizza.tpl.html',
            controller: 'MyPizzaCtrl',
            controllerAs: 'myPizza'
          },
          'layoutView': {
            templateUrl: 'partials/layout.html',
          }
        }

      });
  }
}());
